import styles from '../App.module.css'

export default function Home() {
  return (
    <div className={styles.homeContainer}>
      <span className={styles.homeText}>
        <h1>kae</h1>
        <h1>schrattenholzer</h1>
        <div className={styles.homeBody}>
          <p>
          Software Dev
          </p>
          <p>
           Houseplant Hobbyist and Aspiring Baker
          </p>
          <p>
           Portland, Oregon
          </p>
        </div>
      </span>
    </div>
  )
}
