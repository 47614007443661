import styles from './Header.module.css'
import { NavLink } from 'react-router-dom'



export default function Header() {

  return (
    <header className={styles.headerEl}>
      <div className={styles.linkEl}>
        <NavLink to="/about"  className={styles.menuLink}
        >about</NavLink>  
        <NavLink to="/projects" className={styles.menuLink}>portfolio</NavLink>  
        <NavLink to="/contact" className={styles.menuLink}>contact</NavLink>  
      </div>
      <NavLink to="/" className={styles.nameEl}>
        K. SCHRATTENHOLZER
      </NavLink>
      
    </header>
  )
}
