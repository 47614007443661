import styles from '../App.module.css'
import Project from '../components/Projects/Project.jsx'
import APIPreview from '../assets/apiPreview.png'
import bopPreview from '../assets/bopPreview.png'
import XemPreview from '../assets/askXemPreview.png'
import PSPreview from '../assets/PawStarPreview.png'
export default function Projects() {
  return (
    <div className={styles.projectContainer}>
      <h2>Projects</h2>
      <Project
        title='Bop Simon'
        bio='A sequence based musical memory game, with a virtual synth created with the Tone.js library, React, and Postgres'
        repoLink='https://github.com/bop-simon/bop-simon-frontend'
        liveLink='https://bop-simon-prod.netlify.app/'
        preview={bopPreview}
      />
      <Project
        title='Ask Xem'
        bio='An interactive educational experience for kids to teach about pronouns, and help explore gender concepts, built with React and Supabase'
        repoLink='https://github.com/askxem/Ask-Xem'
        liveLink='https://askxem.netlify.app/'
        preview={XemPreview}
      />
      <Project
        title='Stardew Valley API'
        bio='An API for Stardew Valley information built with Node and PostgreSQL utilizing RESTful conventions'
        repoLink='https://github.com/k-schrattenholzer/ACL-StardewValleyAPI'
        liveLink='https://stardewvalley-api.herokuapp.com/'
        preview={APIPreview}
      />
      <Project
        title='Paw Star Astrology'
        bio='This full stack app, built with React and PostgreSQL, gives pet owners a way to check their pets horoscopes daily'
        repoLink='https://github.com/EmilyDSarani/paw-star-fe'
        liveLink='https://paw-star.netlify.app/'
        preview={PSPreview}
      />
  </div>
  )
}
