import styles from './Project.module.css'

export default function Project({ title, bio, repoLink, liveLink, preview}) {
  return (
    <div className={styles.cardContainer}>
      <div className={styles.cardContent}>
        <h2>{title}</h2>
        <p>{bio}</p>
        <div className={styles.cardLinks}>
            <a href={liveLink}>live</a> 
            <a href={repoLink}>repo</a>
        </div>
      </div>
        <img src={preview} alt={title} />
  </div>
  )
}
