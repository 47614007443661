import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './views/Home.jsx'
import About from './views/About.jsx';
import Projects from './views/Projects.jsx'
import Header from './components/Layout/Header/Header.jsx';
import styles from './App.module.css'
import Contact from './views/Contact.jsx';

export default function App() {

  return (
      <main>
        <BrowserRouter>
          <Header />
          <div className={styles.container}>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/about" element={<About />} />
              <Route path="/projects" element={<Projects />} />
              <Route path="/contact" element={<Contact />} />
            </Routes>
          </div>
        </BrowserRouter>
      </main>
  );
}

