import styles from '../App.module.css'

export default function Contact() {
  return (
    <div className={styles.contactContainer}>
      <div className={styles.contactBody}>
        <p>
            <br />
            Check out my work on
          <a href="https://www.github.com/k-schrattenholzer/">
            Github</a>
            <br />
            Connect on
          <a href="https://www.linkedin.com/in/k-schrattenholzer/">
            LinkedIn</a>
            <br />
            Reach out by  
          <a href="mailto:ktschr11@gmail.com">
            Email</a>
          </p>
      </div>
    </div>
  )
}
