import styles from '../App.module.css'
import uniqueId from 'lodash/uniqueId';

const skills = [
  {name: 'HTML'},
  {name: 'CSS'},
  {name: 'JavaScript'},
  {name: "Jest"},
  {name: "Vow"},
  {name: "Supertest"},
  {name: "React Testing Library"},
  {name: 'PostgreSQL'},
  {name: "Supabase"},
  {name: "Firebase"},
  {name: "React"},
  {name: "Node"},
  {name: "Express"},
  {name: "Adonis"},
  {name: "Superagent"},
  {name: "Heroku"},
  {name: "Netlify"},
  {name: "Postman"},
  {name: "BeeKeeper"},
  {name: "Git"}
]

export default function About() {
  return (
    <div className={styles.meEl}>
      <p>
        Hi! I'm Kae - a dev based in Portland, OR. 
        Experienced building component based apps and RESTful APIs, as well as contributing to remote teams - I appreciate opportunities to leverage my background in accounting &amp; customer service to write clean, readable and tested code.
      </p>
      <h2>Skills</h2>
        <span className={styles.skillsEl}>
          {
          skills.map((skill) => <p key={uniqueId()}>{skill.name}</p>)
          }
        </span>
    </div>
  )
}
